import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	return (
		<Layout>
			<GatsbySeo title={title} language="en" noindex />
			<section>
				<Container className="mt-lg-10 mt-4">
					<Row className="py-5 justify-content-center">
						<Col className="text-center" lg={8}>
							<h1
								style={{ color: "#7CD2F4" }}
								className="display-2 fw-bolder pb-3 "
							>
								Thank you for contacting {companyName}
							</h1>
							<p>We will get back to you as soon as possible</p>
							<Link className="black-link fw-bold" to="/">
								<p> &#8592; Home</p>
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default AboutPage;
